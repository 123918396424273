import cookies from 'browser-cookies';
import { lowerDash } from 'utils/data-layer/helpers/lowerDash';
import { generateDimensionsDataGA4 } from './generateDimensionsDataGA4';
import { productName } from './productName';
import { productIdPrimaryByCookie } from './productIdPrimaryByCookie';
import { getProductAvailabilityStatus } from './getProductAvailabilityStatus';
import { itemVariantParser } from './variantParser';
import { onlyCharactersAndSpace } from 'utils/value-cleaners';
import getCategory5Label from './getCategory5Label';
import { getCouponInProduct } from './getCouponInProduct';

const productPrice = (product, eventName) => {
  const price = product?.unit_price || product?.prices?.to || product?.prices?.cash;
  const quantity = product?.quantity || 1;
  const discount = product?.payment_discount_amount || 0;

  let total;
  if (eventName === 'view_cart'){
    total = parseFloat(((price - discount) / quantity).toFixed(2));
  } else {
    total = parseFloat((price - discount / quantity).toFixed(2));
  }
  return Number(total);
};

export const generateProductDataGA4 = ({
  product,
  stateAfter,
  index,
  eventName,
  deliveries,
}) => {
  const { name, title, brand, quantity, category_id, subcategory_id } = product;

  const productId = productIdPrimaryByCookie(
    cookies.get('ga_cookie_add'),
    product.id || product.sku
  );
  const order = stateAfter?.order?.order;
  const provider = lowerDash(product.seller || product.storeId) || null;

  const findProduct = stateAfter?.basket.basket?.products?.find(
    (item) => item.sku === product?.sku
  );

  const cart = product?.product || null;
  const productOrder = order?.items?.find((el) => el.sku === product?.sku);
  const productBasketSoldBy = `${provider || productOrder?.seller || null}|ent-por:${findProduct?.provider || cart?.provider || null}`;
  const itemVariant = itemVariantParser(product.variations || cart?.variations);
  const productUrl = product?.productUrl || product?.path_url || product?.link;
  const urlParts = productUrl?.split('/') ?? null;
  const categories = urlParts
    ? `${urlParts[urlParts.length - 3]}/${urlParts[urlParts.length - 2]}`
    : null;

  const discountPrices = product?.prices
    ? parseFloat(
        (parseFloat(product?.prices?.to) - parseFloat(product?.prices?.cash)).toFixed(2)
      )
    : null;
  const reviewCount = product?.review_count || product?.reviewCount || 'null';
  const reviewScore = product?.review_score || product?.reviewScore || 'null';
  const categoryName = productName(category_id || cart?.categoryId);
  const subcategoryName = productName(subcategory_id || cart?.subcategoryId);

  const productData = {
    item_id: productId,
    item_name: productName(name || title || cart?.name),
    item_brand: productName(brand || cart?.brand) || null,
    item_category: categories ?? `${categoryName}/${subcategoryName}` ?? null,
    item_category3: `qtd-av:${reviewCount}|av:${reviewScore}|${getProductAvailabilityStatus(
      product.isSoldOut,
      product.notAvailableForDelivery
    )}`,
    item_category4: `ven-por:${productBasketSoldBy}`,
    item_category5: `selo:${
      lowerDash(onlyCharactersAndSpace(product?.badges?.[0]?.text)) || null
    }|tag:${lowerDash(onlyCharactersAndSpace(product?.offerTags?.[0])) || getCategory5Label(product)}`,
    discount: discountPrices ?? product?.payment_discount_amount,
    item_variant: itemVariant,
    price: productPrice(product, eventName),
    quantity: quantity || product?.items?.quantity || 1,
    ...generateDimensionsDataGA4(
      stateAfter,
      product,
      productId,
      index,
      eventName,
      deliveries
    ),
  };

  if (eventName === 'purchase') {
    const paymentDiscountAmount = parseFloat(productOrder?.payment_discount_amount) || 0;
    const productQuantity = parseFloat(productOrder?.quantity) || 1;

    const discountPayment = (paymentDiscountAmount / productQuantity).toFixed(2);
    const pricePayment = Number(productOrder?.payment_unit_price);

    const percentage =
      pricePayment > 0
        ? ((discountPayment / parseFloat(productOrder.unit_price)) * 100).toFixed(2)
        : '0.00';

    productData.price = pricePayment;
    productData.discount = Number(discountPayment);
    productData.dimension77 = `nominal:${discountPayment}|percentual:${percentage}`;
    productData.coupon = getCouponInProduct(product);
    productData.affiliation = 'magalu';
  }

  return productData;
};

export function productsDataGA4({
  products = [],
  stateAfter,
  index,
  eventName = '',
  deliveries = [],
}) {
  return products.map((product) =>
    generateProductDataGA4({ product, stateAfter, index, eventName, deliveries })
  );
}
