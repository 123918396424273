import { getCustomer, isLogged } from 'utils/session';
import { getCityUf, GTM_FALSE, GTM_UNDEFINED } from './helpers';

export function promocodeModalVirtualPageView(pageInfo, _, stateAfter) {
  const customer = getCustomer();
  const { couponsAvailableOnProducts } = pageInfo || {};
  const { shipment: address = {} } = stateAfter.shipment || {};
  const { promocode = null } = stateAfter.basket?.basket || {};

  return {
    event: 'virtualPageview',
    barraLocalizacao: 0,
    cepPreenchido: address?.city && address?.state ? 1 : 0,
    cidadeuf: getCityUf(address?.city || '', address?.state || '').toLowerCase(),
    idUsuario: customer?.id || GTM_FALSE,
    loginAtivo: isLogged() ? 'logado' : 'nao-logado',
    pageCategory: 'nao-se-aplica',
    pageSubcategory: 'nao-se-aplica',
    pageType: 'cupom',
    termoBuscado: promocode || GTM_UNDEFINED,
    listaVazia: couponsAvailableOnProducts.length === 0 ? 1 : 0,
    screenName: 'cupom',
    hitTimestamp: Date.now().toString(),
  };
}

export function promocodeEventCustom(pageInfo) {
  const {
    event = 'select_content',
    content_type = 'cupom',
    content_id = GTM_UNDEFINED,
  } = pageInfo || {};

  return {
    event,
    content_type,
    content_id,
    hitTimestamp: Date.now().toString(),
  };
}
