import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TagButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
  };

  render() {
    const { label, onClick, error } = this.props;

    return (
      <button
        className="btn btn-invisible font-body-default-highlight text-brand-default p-[0]"
        id="event-button"
        onClick={onClick}
        disabled={!!error}>
        {label}
      </button>
    );
  }
}

export default TagButton;
