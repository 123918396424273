import * as cardFlags from '../app/constants/card-flags';
import { onlyNumbers } from '../utils/value-cleaners';

export const defaultCvvLength = 3;
export const DEFAULT_MASK_CREDIT_CARD = '9999 9999 9999 9999 999';

export const DEFAULT_CARDS = {
  [cardFlags.magaluCard]: {
    cvcLength: 3,
    length: [16],
    regex: '^(498556|534448|534447|485103|554480|536804|485104|440132)'
  },
  [cardFlags.visa]: {
    cvcLength: 3,
    length: [13, 16],
    regex: '^(4)'
  },
  [cardFlags.diners]: {
    cvcLength: 3,
    length: [14, 16],
    regex: '^(30|31|35|36|38|39)'
  },
  [cardFlags.amex]: {
    cvcLength: 4,
    length: [15],
    regex: '^(34|37)'
  },
  [cardFlags.magazine]: {
    cvcLength: 3,
    length: [16],
    regex: '^(530599|517914|530780)'
  },
  [cardFlags.magazineGold]: {
    cvcLength: 3,
    length: [16],
    regex: '^(517967|548514|518967|530994|527887)'
  },
  [cardFlags.aura]: {
    cvcLength: 3,
    length: [19],
    regex: '^(507860|530032|532473|532474)'
  },
  [cardFlags.hipercard]: {
    cvcLength: 3,
    length: [13, 16, 19],
    regex: '^(3841|606282|609820|637095|637568|384100|384140|384160)'
  },
  [cardFlags.elo]: {
    cvcLength: 3,
    length: [16],
    regex: '^(401178|5041|5066|5067|509|401179|6277|6362|6363|650|6516|6550|431274|438935|451416|457393|457631|457632|504175|506699|5067|509|627780|636297|636368|650|6516|6550)'
  },
  [cardFlags.mastercard]: {
    cvcLength: 3,
    length: [16],
    regex: '^(5|222763|230717|230744|234028|234076|222896|222904|222913|222898|222899|222907|222908|230650|230870)'
  },
  [cardFlags.virtualDebitEloCardFlag]: {
    cvcLength: 3,
    length: [16],
    regex: '^(50672280|5067228|5067229|509023|509105|509030|506722)'
  }
};

export const getMinCardLength = (cards = DEFAULT_CARDS) => {
  let min = Infinity;
  Object.keys(cards).forEach(flag => {
    const minLength = cards[flag].length[0];
    min = minLength < min && minLength || min;
  });
  return min;
};
export const getMaxCardLength = (cards = DEFAULT_CARDS) => {
  let max = 0;
  Object.keys(cards).forEach(flag => {
    const maxLength = cards[flag].length.slice(-1)[0];
    max = max < maxLength && maxLength || max;
  });
  return max;
};
export const getMinCVCLength = (cards = DEFAULT_CARDS) => {
  let min = Infinity;
  Object.keys(cards).forEach(flag => {
    const minLength = cards[flag].cvcLength;
    min = minLength < min && minLength || min;
  });
  return min;
};
export const getMaxCVCLength = (cards = DEFAULT_CARDS) => {
  let max = 0;
  Object.keys(cards).forEach(flag => {
    const maxLength = cards[flag].cvcLength;
    max = max < maxLength && maxLength || max;
  });
  return max;
};

// Receives an object of cards.
// Returns the key of the card.
// We can receive a custom object of cards.
export const detectCardFlag = (number = '', cards = DEFAULT_CARDS) => {
  const cardNumber = onlyNumbers(number);
  if (cardNumber.length < getMinCardLength(cards)) {
    return '';
  }

  const cardKey = cardNumber && Object.keys(cards).find((key) => {
    const card = cards[key];
    return card.length.indexOf(cardNumber.length) > -1 && new RegExp(card.regex).test(cardNumber) || false;
  });
  return cardKey || '';
};
