import { flatMap, get } from 'lodash';
import slugify from 'utils/slugify';

const formattedServices = (services, product) => {
  return {
    item_name: `${services?.category || null}${services?.duration || null}`,
    item_category: `servicos/${slugify(services?.name)}`,
    item_id: services.id,
    item_variant: product.sku,
    price: Number(services?.price),
    quantity: 1,
  };
};

export const extractFormattedServices = (products) => {
  const formattedServicesArray = flatMap(
    products.filter((product) => get(product, 'services.length', 0) > 0),
    (product) =>
      product.services.map((service) => formattedServices(service, product))
  );

  return formattedServicesArray;
};

