import { productPrice } from './helpers/productData';
import {
  getChannelStoreId,
  getChannelStoreName,
  GTM_FALSE,
  GTM_TRUE,
  GTM_UNDEFINED,
  productsData,
} from './helpers';

export const purchaseLayer = (stateAfter) => {
  const layer = {};
  const order = stateAfter.order.order;
  const { ecommerceProducts, total } = productsData(order.items, stateAfter);

  layer.event = 'ecommerce_purchase';
  layer.eventCategory = 'purchase';
  layer.eventAction = 'page-load';
  layer.eventLabel = 'confirm_order';
  layer.screenName = 'pedido concluído';

  layer.jurosParcela = order.payment.interest_rate > 0 ? GTM_TRUE : GTM_FALSE;
  layer.parcelas = order.payment.number_of_installments;
  layer.pagamento = order.payment.payment_method_id;
  layer.payment_type = `${order.payment.payment_method_id}:${order.payment.payment_method_id}`;

  layer.nomeDaLoja = getChannelStoreName(stateAfter?.channel);
  layer.idDaLoja = getChannelStoreId(stateAfter?.channel);

  layer.hitTimestamp = `${Date.now()}`;
  layer.newBuyer = (order.isNewBuyer ?? GTM_UNDEFINED).toString();
  layer.value = total;
  layer.transaction_id = order.id;
  layer.basketId = stateAfter.basket.basket.id;

  layer.ecommerce = {};
  layer.ecommerce.purchase = {};
  layer.ecommerce.purchase.actionField = {};
  layer.ecommerce.purchase.actionField.id = order.id;
  layer.ecommerce.purchase.actionField.currency = 'BRL';
  layer.ecommerce.purchase.actionField.affiliation = 'site_magalu';
  layer.ecommerce.purchase.actionField.revenue = `${order.totalAmount}`;
  layer.ecommerce.purchase.actionField.shipping = `${order.shippingAmount}`;
  layer.ecommerce.purchase.actionField.coupon = stateAfter.promocode.promocode || '';

  layer.ecommerce.purchase.products = ecommerceProducts;

  return layer;
};

export const purchaseCloudRetailLayer = (stateAfter) => {
  const order = stateAfter.order.order;
  const cartId = stateAfter.basket.basket.id;

  const productDetails = order?.items?.map((product) => ({
    product: {
      id: product.sku,
      priceInfo: {
        originalPrice: product.unit_price || product.prices.cash,
        price: productPrice(product),
        currencyCode: 'BRL',
      },
    },
    quantity: product.quantity || 1,
  }));

  return {
    event: 'cloud_retail',
    cloud_retail: {
      cartId,
      eventType: 'purchase-complete',
      productDetails,
      purchaseTransaction: {
        id: order.id,
        revenue: order.totalAmount,
        currencyCode: 'BRL',
      },
      userInfo: {
        userId: order.customerId,
      },
    },
  };
};
