import toArray from 'lodash/toArray';
import URL from 'url-parse';

import * as routeNames from 'constants/route-names';

function DomainNotAllowedError(domain) {
  this.name = 'DomainNotAllowedError';
  this.message = `The domain ${domain} is not allowed to redirect to`;
  this.stack = (new Error()).stack;
}
DomainNotAllowedError.prototype = Object.create(Error.prototype);
DomainNotAllowedError.prototype.constructor = DomainNotAllowedError;

export function isUrlAllowed(hostname) {
  const allowedDomainsRegexList = toArray(SETTINGS.allowedDomainsForNext).map(
    domain => new RegExp(domain + '$', 'i')
  );
  return allowedDomainsRegexList.some(re => re.test(hostname));
}

export const redirectToNextUrl = (
  goFunc,
  { next, origin = '', nextQuery = {} }
) => {
  const nextUrl = decodeURIComponent(next);

  if (origin) {
    const { hostname } = new URL(nextUrl);

    if (isUrlAllowed(hostname)) {
      window.location.assign(nextUrl);
    } else {
      throw new DomainNotAllowedError(hostname);
    }
  } else {
    const params = {
      pathname: nextUrl,
      query: nextQuery,
    };
    goFunc(params);
    return params;
  }
};

export const redirectToLogin = (router, next, location) => {
  const { tipo, origin, ...nextQuery } = location?.query || {};

  const nextRouteParams = {
    pathname: routeNames.login,
    query: {
      next,
      origin,
      tipo,
      ...nextQuery,
    }
  };

  router.push(nextRouteParams);

  return nextRouteParams;
};

export const isExternalUrl = (path) => {
  return new URL(document.baseURI).origin !== new URL(path, document.baseURI).origin;
};
