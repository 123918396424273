import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import TagButton from './TagButton';

class InfoPromoCodeTag extends Component {
  static propTypes = {
    promo: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string.isRequired,
    }).isRequired,
    button: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
    onRemove: PropTypes.func,
    error: PropTypes.string,
    listItem: PropTypes.bool,
  };

  render() {
    const { promo, button, error, success, listItem, onRemove } = this.props;
    const defaultClass = 'flex items-center rounded-md';

    return (
      <div>
        <div className="flex">
          <div
            id="info-tag"
            className={classname(
              'flex flex-1 pl-2xsm p-3xsm justify-between items-center gap-2xsm rounded-md font-body-small-highlight text-on-surface-1 md:p-2xsm',
              error ? 'bg-surface-container-mid' : 'bg-lime-500'
            )}>
            <div className="flex items-center px-2xsm py-3xsm gap-2xsm truncate">
              <span
                className="ti ti-cupom-journey font-body-default"
                id="coupon-default-icon"
              />
              <p className="pt-3xsm truncate" id="promo-message">{promo.message}</p>
            </div>

            {listItem && success && (
              <div
                className={classname(
                  defaultClass,
                  'flex-1 justify-center font-body-small-highlight bg-lime-400 text-surface-high p-xsm gap-2xsm'
                )}
                id="coupon-applied">
                <span className="ti ti-check w-md h-md flex justify-center items-center font-xlg-bold pr-xsm" />
                {success}
              </div>
            )}

            {listItem && !success && (
              <div
                className={classname(
                  defaultClass,
                  'flex-1 justify-between bg-info-default-inverted text-on-surface-4 px-xsm py-2xsm'
                )}>
                <span className={classname(error && 'line-through')} id="promo-code">
                  {promo.code}
                </span>
                <TagButton label={button.label} onClick={button.onClick} error={error} />
              </div>
            )}

            {!listItem && (
              <div
                className={classname(
                  defaultClass,
                  'justify-between bg-info-default-inverted text-on-surface-4 px-xsm py-2xsm'
                )}>
                <TagButton label={button.label} onClick={button.onClick} error={error} />
              </div>
            )}
          </div>

          {listItem && success && (
            <button
              className="btn btn-sm btn-danger-invisible font-body-default-highlight py-3xsm px-2xsm"
              onClick={onRemove}>
              Remover
            </button>
          )}
        </div>

        {!!error && (
          <span className="pt-xsm text-danger-default font-body-small" id="coupon-error">
            {error}
          </span>
        )}
      </div>
    );
  }
}

export default InfoPromoCodeTag;
