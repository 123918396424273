export const root = '';
export const basket = 'sacola';
export const login = 'identificacao';
export const logout = 'sair';
export const signup = 'cadastro';
export const address = 'endereco';
export const delivery = 'entrega';
export const editRegistration = 'cadastro/editar';
export const payment = 'pagamento';
export const review = 'revisao';
export const confirmOrder = 'comprovante';
export const receipt = `${confirmOrder}/imprimir`;
export const redirectToMagaluEmpresas = 'redirect/magalu-empresas';

// Site routes
export const channelLogin = 'cliente/login';
export const channelSignup = 'cliente/cadastro';
export const channelEditRegistration = 'cliente/editar';
export const channelCustomerArea = 'cliente/seu-espaco';
export const channelCustomerAreaConfirm = 'cliente/seu-espaco/confirmacao';
export const channelCustomerAreaChangeEmail = 'cliente/seu-espaco/email';
export const channelCustomerAreaChangeCellphone = 'cliente/seu-espaco/telefone';
export const channelCustomerAreaAddress = 'seu-espaco/endereco-entrega';

export const loginPasswordlessOTP = 'validar-credenciais';
export const loginPasswordlessWithPhone = 'validar-credenciais/telefone';
export const loginPasswordlessWithEmail = 'validar-credenciais/email';