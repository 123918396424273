import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isLogged,
  getCurrentStep,
  userInUnifiedCartRolloutAndWithoutPaymentBasketId,
} from 'utils/session';
import { optionClick as clickEvent } from 'reducers/navigation';
import {
  mergeBasketPayment as mergeBasketPaymentReducer,
  clearError as clearErrorReducer,
} from 'reducers/basket';
import {
  basket as routeBasket,
  login as routeLogin,
  signup as routeSignup,
  editRegistration as routeEditRegistration,
  delivery as routeDelivery,
  address as routeAddress,
  payment as routePayment,
  review as routeReview,
} from 'constants/route-names';
import { statusColors } from 'constants/checkout-shortcuts-status';
import redirectToWallet from 'utils/redirectToWallet';
import isWalletEnable from 'utils/isWalletEnable';
import classname from 'classname';
import NavigationIcon from '../NavigationIcon';
import './styles.scss';
import MagazineModal from 'components/MagazineModal/MagazineModal';

const basketPage = 'Sacola';
const basketLogin = 'Identificação';
const basketDelivery = 'Entrega';
const basketPayment = 'Pagamento';
const basketReview = 'Revisão';

const pageName = {
  [routeBasket]: basketPage,
  [routeLogin]: basketLogin,
  [routeSignup]: basketLogin,
  [routeEditRegistration]: basketLogin,
  [routeDelivery]: basketDelivery,
  [routeAddress]: basketDelivery,
  [routePayment]: basketPayment,
  [routeReview]: basketReview,
};

const mapStateToProps = ({ basket, order }) => ({
  basket: basket.basket,
  errorMessage: basket.errorMessage,
  itemsOutOfStock: order.itemsOutOfStock,
  itemsNotAllowedForCompanies: order.itemsNotAllowedForCompanies,
});

const mapDispatchToActions = {
  optionClick: clickEvent,
  mergeBasketPayment: mergeBasketPaymentReducer,
  clearError: clearErrorReducer,
};

@connect(mapStateToProps, mapDispatchToActions)
export default class NavigationOption extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static propTypes = {
    routeName: PropTypes.string.isRequired,
    optionClick: PropTypes.func.isRequired,
    status: PropTypes.any.isRequired,
    basket: PropTypes.object.isRequired,
    mergeBasketPayment: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
  };

  routeToNavigate = (route) => {
    const { optionClick } = this.props;

    optionClick(route, getCurrentStep());

    if (route === routeBasket) {
      return '';
    }

    return isLogged() ? route : routeLogin;
  };

  handleNavigation = (route) => {
    const { router } = this.context;

    const path = this.routeToNavigate(route);

    if (router) {
      router.push(path);
      this.forceUpdate();
    } else if (path !== 'entrega') {
      window.location.assign(`/#/${path}`);
    }
  };

  render() {
    const { routeName, status, errorMessage, clearError } = this.props;
    const statusName = Object.entries(statusColors).find(
      (properties) => properties[1] === status[routeName]
    )[0];

    const buttonClassName = `NavigationOption-button-${statusName}`;
    const navigationOptionProgressClass = `NavigationOption-progress${
      isWalletEnable() ? '-wallet' : ''
    }`;
    const progressClassName = `${navigationOptionProgressClass}-${statusName}`;
    const titleClassName = `NavigationOption-title-${statusName}`;
    const routeTitleClassName = `NavigationOption-title-${routeName}`;
    const navigationOptionProgressClasses = classname(
      navigationOptionProgressClass,
      progressClassName
    );

    const routeIsFromCheckout = () =>
      routeName === routePayment ||
      routeName === routeDelivery ||
      routeName === routeLogin;

    const mergeBasket = () => {
      const {
        mergeBasketPayment,
        basket: { products = [], campaignCode },
        itemsOutOfStock = [],
        itemsNotAllowedForCompanies = [],
      } = this.props;

      return mergeBasketPayment(
        products,
        itemsOutOfStock,
        itemsNotAllowedForCompanies,
        campaignCode
      );
    };

    return (
      <div className="NavigationOption-container">
        <MagazineModal
          className="NavigationOption-Modal"
          isOpen={!!errorMessage}
          onRequestClose={clearError}>
          {errorMessage}
        </MagazineModal>
        <div className="NavigationOption-shortcut">
          <button
            className={`NavigationOption-button ${buttonClassName}`}
            onClick={() => {
              if (
                (routeName === routePayment || routeName === routeReview) &&
                isWalletEnable()
              ) {
                redirectToWallet(routeName);
                return;
              }
              if (
                routeIsFromCheckout() &&
                userInUnifiedCartRolloutAndWithoutPaymentBasketId()
              ) {
                return mergeBasket().then(() => {
                  const route = routeName === routeDelivery ? routeAddress : routeName;
                  this.handleNavigation(route);
                });
              }
              this.handleNavigation(routeName);
            }}
            title={routeName === routeLogin ? 'identificação' : routeName}
            id={`button-${routeName}`}
            disabled={statusName === 'current'}>
            <NavigationIcon routeName={routeName} status={status} />
          </button>
          {routeName !== routePayment && !isWalletEnable() && (
            <div className={navigationOptionProgressClasses} />
          )}
          {routeName !== routeReview && isWalletEnable() && (
            <div className={navigationOptionProgressClasses} />
          )}
        </div>
        <span
          className={`NavigationOption-title ${titleClassName} ${routeTitleClassName}`}>
          {pageName[routeName]}
        </span>
      </div>
    );
  }
}
