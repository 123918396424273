import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class PromoCodeContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    success: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { title, success, children } = this.props;
    const defaultClass = 'p-xsm mb-md border-thin rounded-md';

    return (
      <div
        id="coupon-container"
        className={classname(
          defaultClass,
          success ? 'border-lime-300' : 'border-on-surface-7'
        )}>
        <div>
          <p
            className="font-body-small-highlight text-on-surface-1 pb-md"
            id="title-coupon-container">
            {title}
          </p>
          {children}
        </div>
      </div>
    );
  }
}

export default PromoCodeContainer;
