import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookies from 'browser-cookies';

import * as routeNames from 'constants/route-names';
import * as customerMessages from 'constants/customer-messages';
import { FACEBOOK, GOOGLE } from 'constants/social-login';
import { stwuCookieName } from 'constants/stewie-constants';
import { LOGIN_ERROR_CODES } from 'constants/login';

import { getNextUrl, getOriginUrl } from 'utils/login';
import { redirectToNextUrl, isExternalUrl } from 'utils/redirect';
import { socialLoginViewContent } from 'utils/data-layer/helpers';

import { socialProfile } from 'api/models/customer-models';

import {
  showLoading,
  hideLoading,
} from 'reducers/loading';
import * as signupAvailableActions from 'reducers/signup-available';
import * as socialLoginActions from 'reducers/social-login';
import * as failActions from 'reducers/fail';

import SocialLoginBox from 'components/Login/SocialLoginBox/SocialLoginBox';
import { validateCaptcha } from 'components/Form/Captcha/Captcha';
import {adsCollectorLogin} from 'utils/magalu-ads-collector/magalu-ads-collector';
import OtherErrorsModal from './Login/OtherErrorsModal';
import { nationalResetPasswordByOtpIdentificationUrl } from 'constants/external-urls';

const { object, func, bool } = PropTypes;

const mapStateToProps = ({ channel, socialLogin }) => ({
  configs: channel.configs,
  socialInfo: socialLogin,
});

const mapDispatchToActions = {
  ...socialLoginActions,
  ...signupAvailableActions,
  ...failActions,
  showLoading,
  hideLoading,
};

export class SocialLoginBoxContainer extends Component {
  static propTypes = {
    requestProfile: func.isRequired,
    receiveProfile: func.isRequired,
    fetchSocialLogin: func.isRequired,
    fetchLoginAvailableFromSocial: func.isRequired,
    postSocialLogin: func.isRequired,
    fail: func.isRequired,
    location: object.isRequired,
    configs: object.isRequired,
    showLoading: func.isRequired,
    hideLoading: func.isRequired,
    socialInfo: object.isRequired,
    isSignup: bool
  };

  state = {
    openErrorModal: false,
    errorModalType: null,
  }

  static defaultProps = {
    isSignup: false
  }

  static contextTypes = {
    router: object.isRequired
  };

  socialCallback = source => {
    return profile => {
      const {
        receiveProfile,
        fetchSocialLogin,
        fetchLoginAvailableFromSocial,
        postSocialLogin,
        fail,
        location: { query },
        isSignup,
      } = this.props;
      const { router } = this.context;
      const { origin, next } = query;
      const cookieStwu = cookies.get(stwuCookieName);

      this.props.showLoading('SocialLogin');

      if (profile) {
        const nextUrl = next || getNextUrl(location.href);
        const originUrl = origin || getOriginUrl(nextUrl);

        const goToNextUrl = () => redirectToNextUrl(router.replace, {
          next: nextUrl,
          origin: originUrl,
        });
        const goToSignup = () => redirectToNextUrl(router.push, { next: routeNames.signup, nextQuery: query });
        const profileWithSource = Object.assign({}, socialProfile(source, profile), { source });
        const { socialClientId, accessToken, email } = profileWithSource;
        receiveProfile(profileWithSource);

        return fetchSocialLogin(source, accessToken, socialClientId, email)
          .then(async (res) => {
            await adsCollectorLogin(res.clientId, cookieStwu);
            this.props.hideLoading('SocialLogin');
            if (res?.enabledCustomerCartMergeRedirect && !isExternalUrl(nextUrl)) {
              return router.push({ pathname: `/${routeNames.root}` });
            }
            goToNextUrl();
          })
          .catch(err => {
            const status= err?.status;
            const errorCode = err?.response?.body?.error_code;
            const mfaId = err?.response?.body?.mfa_id;
            this.props.hideLoading('SocialLogin');

            if (status === 404) {
              validateCaptcha('pre_signup_email')
                .then((captchaToken) => fetchLoginAvailableFromSocial(captchaToken))
                .then(goToSignup)
                .catch(({ errored }) => {
                  if (errored) {
                    return fail(
                      customerMessages.socialLoginFail,
                      status,
                      'Failed when trying to fetch login after social login attempt'
                    );
                  }

                  return postSocialLogin()
                    .then(goToNextUrl)
                    .catch(() => {});
                });
            }
            if(isSignup) {
              return this.setOpenErrorModal(LOGIN_ERROR_CODES.DEFAULT);
            }

            if(status === 403 && errorCode === LOGIN_ERROR_CODES.INVALID_SOCIAL_LOGIN) {
              socialLoginViewContent({ content_id: 'erro-bloqueio:sua-conta-esta-inativa'});

              return this.setOpenErrorModal(LOGIN_ERROR_CODES.INACTIVE_LOGIN);
            }

            if(status === 401 && errorCode === LOGIN_ERROR_CODES.INVALID_REGISTER) {
              socialLoginViewContent({ content_id: 'erro-bloqueio:problema-no-acesso-da-conta'});

              return this.setOpenErrorModal(LOGIN_ERROR_CODES.INVALID_REGISTER);
            }

            if (status === 401 && mfaId) {
              return this.handleRedirectToReview(mfaId);
            }

            socialLoginViewContent({ content_id: 'ocorreu-um-problema-login'});

            return this.setOpenErrorModal(LOGIN_ERROR_CODES.DEFAULT);

          });
      }

      this.props.hideLoading('SocialLogin');
    };
  };

  setOpenErrorModal = (errorModalType) => this.setState({ errorModalType, openErrorModal: true });

  closeErrorModal = () => this.setState({ openErrorModal: false });

  handleRedirectToReview = (mfaId) => {
    window.location.assign(`${nationalResetPasswordByOtpIdentificationUrl}/${mfaId}${this.props.location.search}`);
  }

  render() {
    const { errorModalType, openErrorModal } = this.state;

    return (
      <div>
        <SocialLoginBox
          FBAppId={this.props.configs.fb_app_id}
          onClick={this.props.requestProfile}
          FBCallback={this.socialCallback(FACEBOOK)}
          GoogleCallback={this.socialCallback(GOOGLE)}
          googleClientId={this.props.configs.google_client_id}
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
          isSignup={this.props.isSignup}
        />
        <OtherErrorsModal open={openErrorModal} type={errorModalType} onClose={this.closeErrorModal}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SocialLoginBoxContainer);
