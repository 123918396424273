import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import isEqual from 'lodash/isEqual';

import BobbyCompactProduct from 'components/Bobby/BobbyCompactProduct/BobbyCompactProduct';
import arrow from 'components/Bobby/Arrow';

import Responsive from 'components/Responsive';

import './Bobby.scss';

class BobbyCarouselCompact extends Component {
  shouldComponentUpdate(nextProps) {
    const nextProductsIds = nextProps.products.map(({ id }) => id);
    const currentProductsIds = this.props.products.map(({ id }) => id);

    return !isEqual(nextProductsIds, currentProductsIds);
  }

  render() {
    const {
      products = [],
      handleAddClick,
      isMobile,
      handleLinkClick,
      showcaseTitle,
    } = this.props;
    const CAROUSELSIZE_DEFAULT = 2;
    const CAROUSELSIZE_MOBILE = 1;

    const carouselSize = isMobile ? CAROUSELSIZE_MOBILE : CAROUSELSIZE_DEFAULT;
    const showArrow = products.length > carouselSize;

    const productsShowing = (current) => {
      const last =
        products.length > current + carouselSize
          ? current + carouselSize
          : products.length;
      this.props.onProductImpression(products, current, last);
    };

    productsShowing(0);

    return (
      <div className="BobbyCarousel">
        <div className="BobbyContainer-title">
          {showcaseTitle ? (
            <span dangerouslySetInnerHTML={{ __html: showcaseTitle }} />
          ) : (
            <span>
              Aproveite e <b>compre junto</b>:
            </span>
          )}
        </div>
        <div className="BobbyContainer--compact">
          <Slider
            dots={isMobile}
            infinite={false}
            speed={500}
            slidesToShow={carouselSize}
            slidesToScroll={carouselSize}
            prevArrow={arrow('prev', showArrow)}
            nextArrow={arrow('next', showArrow)}
            afterChange={(current) => productsShowing(current)}>
            {products.map((product, index) => (
              <BobbyCompactProduct
                key={product.stewieProductId}
                handleAddClick={handleAddClick}
                handleLinkClick={(event) => handleLinkClick(event, product, index)}
                {...product}
                priceDescription={
                  product.best_price?.payment_method_description || 'à vista'
                }
                carouselName={showcaseTitle ? showcaseTitle : 'Aproveite e compre junto'}
                product={product}
                index={index}
              />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

BobbyCarouselCompact.PropTypes = {
  products: PropTypes.array,
  handleAddClick: PropTypes.func.isRequired,
  onProductImpression: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  handleLinkClick: PropTypes.func.isRequired,
  showcaseTitle: PropTypes.string.isRequired,
};

export { BobbyCarouselCompact };
export default Responsive(BobbyCarouselCompact);
