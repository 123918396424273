import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class ProductPromoCodeTag extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['small', 'medium']).isRequired,
    promo: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { type, promo } = this.props;

    return (
      <div
        className={classname(
          'inline-flex items-center py-2xsm px-2xsm gap-2xsm w-max text-on-surface-1 bg-lime-500',
          type === 'small' && 'rounded-xsm font-detail-default',
          type === 'medium' && 'rounded-sm font-body-small-highlight'
        )}>
        <span
          className={classname(
            'ti ti-cupom-journey',
            type === 'small' && 'font-body-xsmall',
            type === 'medium' && 'font-body-default'
          )}
          id="coupon-default-icon"
        />
        {promo.message}
      </div>
    );
  }
}

export default ProductPromoCodeTag;
