import { getCustomer } from 'utils/session';
import { GTM_UNDEFINED, productsDataGA4 } from './helpers';
import getBasketTaxes from './helpers/getBasketTaxes';
import { extractFormattedServices } from './helpers/extractFormattedServices';

export const purchaseLayerGA4 = (stateAfter) => {
  const layer = {};
  const cartData = JSON.parse(localStorage.getItem('cartData'));
  const order = stateAfter?.order?.order;

  const products =
    stateAfter?.basket?.basket?.products.length > 0
      ? stateAfter?.basket?.basket?.products
      : (cartData?.items ?? []);
  const deliveries = cartData?.deliveries;
  const taxesTotals = {
    icms: cartData?.totals?.foreignerTax?.totalIcms ?? '0.00',
    importValue: cartData?.totals?.foreignerTax?.totalImportTax ?? '0.00',
  };

  const { tax } = getBasketTaxes(
    stateAfter?.basket?.basket?.totals &&
      Object.values(stateAfter?.basket?.basket?.totals).every((value) => value === '0.0')
      ? taxesTotals
      : stateAfter?.basket?.basket?.totals
  );

  const services = extractFormattedServices(products);

  const eventName = 'purchase';
  layer.event = eventName;
  layer.screenName = 'pedido-concluido';

  layer.value = order.totalAmount || null;
  layer.mundos = 'magalu';

  const customer = getCustomer();
  layer.idUsuario = customer?.id || null;
  layer.basketId = stateAfter?.basket?.basket?.id || cartData.id || null;
  layer.newBuyer = (order?.isNewBuyer ?? GTM_UNDEFINED).toString() || null;
  layer.payment_type = `${order.payment.payment_method_id}:${order.payment.payment_method_id}`;
  layer.coupon = stateAfter?.basket?.basket?.promocode?.toLowerCase() || GTM_UNDEFINED;

  layer.parcelas =
    order?.payment?.payment_method_id === 'pix'
      ? order?.payment?.number_of_installments
      : null;
  layer.shipping = order?.shippingAmount ?? null;
  layer.affiliation = 'site_magalu';
  layer.currency = 'BRL';
  layer.transaction_id = order.id;
  layer.items = productsDataGA4({
    products,
    stateAfter,
    index: null,
    eventName,
    deliveries,
  });

  layer.items.push(...services);

  layer.tax = tax;

  return layer;
};
