import isInternationalPrice from 'utils/isInternationalPrice';
import { GTM_INTERNATIONAL_PURCHASE, GTM_UNDEFINED } from './constants';
import { couponsAvailableOnProducts } from 'utils/couponHelpers';
import { formatCouponTag } from './formatCouponTag';

export const getTags = (product) => {
  const hasInternationalTag = isInternationalPrice(product);
  const [coupon] = couponsAvailableOnProducts([product]);

  if (!coupon && !hasInternationalTag) {
    return null;
  }

  let tags = 'tags:';

  if (hasInternationalTag) {
    tags += `${GTM_INTERNATIONAL_PURCHASE};`;
  }

  if (coupon) {
    const formattedCouponTag = formatCouponTag(coupon);
    tags += `${formattedCouponTag};`;
  }

  return tags;
};
