import React, { Component } from 'react';
import { toBRL, toBRLwithZero } from 'utils/currency';
import PropTypes from 'prop-types';
import { cardDescription, pixDescription } from 'constants/price-descriptions';

class BasketResume extends Component {
  static propTypes = {
    totals: PropTypes.shape({
      to: PropTypes.string.isRequired,
      cash: PropTypes.string,
      amountDescription: PropTypes.string,
      checkoutAmountDescription: PropTypes.string,
    }).isRequired,
    discounts: PropTypes.shape({
      promocode: PropTypes.string,
    }),
    promocode: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      totals: { to, cash, amountDescription, checkoutAmountDescription },
      discounts,
      promocode,
      onClick,
    } = this.props;

    return (
      <div className="w-full left-[0] bottom-[-18px] flex flex-col items-start w-full gap-md bg-surface-container-lowest">
        <div className="w-full pb-md text-on-surface-2">
          {!!promocode && discounts?.promocode && (
            <p className="flex justify-between gap-sm font-body-small mb-xsm">
              <span className="gap-2xsm">Cupom:</span>
              <span
                className="text-on-success-default-inverted"
                id="resume-coupon-discount">
                - {toBRLwithZero(discounts?.promocode)}
              </span>
            </p>
          )}

          <p className="flex justify-between gap-sm font-body-default-highlight">
            <span className="h-lg">Total:</span>
            <span className="text-right gap-xsm" id="resume-checkout-amount">
              {`${toBRL(cash)} ${checkoutAmountDescription || pixDescription} `}
              <br />
              <span className="font-body-xsmall text-on-surface-4">
                {`ou ${toBRL(to)} ${amountDescription || cardDescription}`}
              </span>
            </span>
          </p>

          <button
            className="btn btn-success flex justify-center w-full mt-md"
            onClick={onClick}>
            Confirmar
          </button>
        </div>
      </div>
    );
  }
}

export default BasketResume;
