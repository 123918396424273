export const LOGIN_ERROR_CODES = {
  MAX_ATTEMPTS: 'max_attempts_reached',
  INACTIVE_LOGIN: 'inactive_login',
  INVALID_LOGIN: 'invalid_login',
  LOGIN_NOT_FOUND: 'login_not_found',
  INVALID_REGISTER: 'invalid_register',
  INVALID_SOCIAL_LOGIN : 'invalid_social_login',
  IN_REVIEW: 'in_review',
  DEFAULT: 'other_errors',
  EXPIRE: 'expire',
  INCOMPLETE_REGISTER: 'incomplete_register',
};