import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import './DialogBottomSheet.scss';

class DialogBottomSheet extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node,
    title: PropTypes.node,
    footerContent: PropTypes.node,
    containerClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    footerShadow: PropTypes.bool,
  };

  handleClose = (e) => {
    const { onClose } = this.props;
    e?.preventDefault?.();
    onClose?.();
  };

  handleEsc = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (open) {
      document.addEventListener('keydown', this.handleEsc);
    }

    if (prevProps.open && !open) {
      return document.removeEventListener('keydown', this.handleEsc);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc);
  }

  render() {
    const {
      title,
      children,
      open,
      footerContent,
      footerShadow,
      onClose,
      containerClassName,
      headerClassName,
    } = this.props;

    return (
      <div className={`bottom-sheet ${open ? 'visible' : ''}`}>
        <div
          className={`bottom-sheet-overlay ${!!onClose ? 'cursor-pointer' : ''}`}
          onClick={this.handleClose}
        />

        <div
          className={classname(
            'bottom-sheet-container md:!h-fit md:max-w-[380px] md:left-1/2 md:top-1/2 md:!-translate-x-1/2 md:!-translate-y-1/2 md:rounded-2xlg',
            containerClassName
          )}>
          <header
            className={classname(
              'bottom-sheet-header font-md-bold z-10',
              headerClassName
            )}>
            {title}
            {!!onClose && (
              <button
                onClick={this.handleClose}
                className="bottom-sheet-close !absolute right-md top-sm"
              />
            )}
          </header>
          <section className="bottom-sheet-content">{children}</section>
          {footerContent && (
            <footer
              className={classname('bottom-sheet-footer', {
                'elevation-up-md': footerShadow,
              })}>
              {footerContent}
            </footer>
          )}
        </div>
      </div>
    );
  }
}

DialogBottomSheet.defaultProps = {
  footerContent: null,
  containerClassName: '',
  headerClassName: '!justify-center',
  footerShadow: false,
};

export default DialogBottomSheet;
