import {
  addToCart,
  productListView,
  productClick,
  selectItem,
} from 'utils/data-layer/recommendation';

import { addToCartGA4, viewItemList } from 'utils/data-layer/recommendationGA4';

import {
  basketPageview,
  basketCheckoutProgress,
  basketViewCart,
  basketTax,
  changeItemQty,
  removeToCart,
  basketShippingCalc,
  basketShippingCalcImpression,
  removeService,
  basketShippingCalcError,
} from 'utils/data-layer/basket';

import {
  changeItemQtyGA4,
  eventActionBasketGA4,
  removeFromCartGA4,
} from 'utils/data-layer/basketGA4';

import {
  startChangeCustomer,
  successChangeCustomer,
  dataChangeCustomer,
  dataChangeResponse,
  customVirtualPageview,
} from 'utils/data-layer/customerArea';
import {
  loginCheckoutProgress,
  loginResponse,
  loginPageview,
} from 'utils/data-layer/login';
import {
  signupCreateMpay,
  signupKnowMoreTerms,
  signupResponse,
} from 'utils/data-layer/signup';
import {
  signupOtpVirtualPageView,
  changeDataScreenOtp,
  selectedModeSecurityValidationOtpClick,
  otpResponse,
  selectOtherConfirmationOtpClick,
  resendOtpClick,
} from 'utils/data-layer/otp';
import { socialLoginResponse } from 'utils/data-layer/social-login';
import {
  paymentCheckoutProgress,
  paymentPageview,
  paymentShippingCalcImpression,
} from 'utils/data-layer/payment';
import {
  orderPageview,
  copyPixClick,
  orderAddPaymentInfo,
  orderAddPaymentInfoGA4,
} from 'utils/data-layer/order';
import { navigationOptionClick } from 'utils/data-layer/navigation';
import {
  ADD_TO_CART,
  ADD_TO_CART_GA4,
  PRODUCT_LIST_VIEW,
  PRODUCT_CLICK,
  SELECT_ITEM,
  VIEW_ITEM_LIST,
} from 'reducers/bobby';
import {
  BASKET_READY,
  BASKET_CHECKOUT_PROGRESS,
  BASKET_VIEW_CART,
  BASKET_TAX,
  DID_UPDATE_ITEM_QTY,
  DID_UPDATE_ITEM_QTY_GA4,
  TRIGGER_CLICK_EVENT_GA4,
  DID_REMOVE_ITEM,
  DID_REMOVE_ITEM_GA4,
  DID_REMOVE_SERVICES,
  RECEIVED_IMPRESSION,
} from 'reducers/basket';

import {
  TRACK_START_CHANGE as CUSTOMER_AREA_TRACK_START_CHANGE,
  TRACK_SUCCESS_CHANGE as CUSTOMER_AREA_TRACK_SUCCESS_CHANGE,
  TRACK_DATA_CHANGE as CUSTOMER_AREA_TRACK_DATA_CHANGE,
  TRACK_DATA_CHANGE_ERROR as CUSTOMER_AREA_TRACK_DATA_CHANGE_ERROR,
  TRACK_SCREENVIEW_APP as CUSTOMER_AREA_TRACK_SCREENVIEW_APP,
} from 'reducers/customer-area';
import {
  LOGIN_VIEW,
  LOGIN_CHECKOUT_PROGRESS,
  TRACK_ERROR as LOGIN_TRACK_ERROR,
  TRACK_SUBMIT as LOGIN_TRACK_SUBMIT,
  RECEIVED as LOGIN_RECEIVED,
  LOGIN_RECEIVED_GA4,
} from 'reducers/login';
import {
  TRACK_ERROR as SIGNUP_TRACK_ERROR,
  TRACK_SUBMIT as SIGNUP_TRACK_SUBMIT,
  RECEIVED as SIGNUP_RECEIVED,
  RECEIVED_GA4 as SIGNUP_RECEIVED_GA4,
  DID_CREATE_MPAY,
  DID_TERMS_KNOW_MORE_VIEW,
  VIEW_SINGUP_GA4,
  ACCEPT_PRIVACY_POLICY_GA4,
  READ_POLICY_GA4,
  DID_CREATE_MPAY_GA4,
  OPTIONS_NOTIFICATIONS_GA4,
  LEARN_MORE_MPAY_GA4,
  TERMS_USE_GA4,
  CONTINUE_CHECKOU_GA4,
} from 'reducers/signup';
import {
  REQUEST_PROFILE,
  TRACK_ERROR as SOCIAL_LOGIN_TRACK_ERROR,
  RECEIVED as SOCIAL_LOGIN_RECEIVED,
  RECEIVED_LOGIN_GA4 as SOCIAL_LOGIN_RECEIVED_GA4,
  RECEIVED_SIGN_UP_GA4,
} from 'reducers/social-login';
import {
  PAYMENT_VIEW,
  PAYMENT_CHECKOUT_PROGRESS,
  ORDER_VIEW,
  COPY_PIX_CLICK,
  REQUEST_PLACE_ORDER_GA4,
  RECEIVED_PLACE_ORDER,
  RECEIVED_PLACE_ORDER_GA4,
  PAYMENT_SHIPPING_CALC_IMPRESSION,
  ERROR_APPLICATION,
} from 'reducers/order';
import {
  RECEIVED_CLICK,
  RECEIVED_SHIPPING_CALC_ERROR_IN_BASKET,
} from 'reducers/shipment';
import {
  TRACK_CLICK as OTP_TRACK_CLICK,
  TRACK_SUCCESS as OTP_TRACK_SUCCESS,
  TRACK_ERROR as OTP_TRACK_ERROR,
  TRACK_PAGE_VIEW as OTP_TRACK_PAGE_VIEW,
  TRACK_CHANGE_DATA_SCREEN_OTP as CUSTOMER_AREA_TRACK_CHANGE_DATA_SCREEN_OTP,
  RESET as OTP_SELECT_OTHER_CONFIRMATION,
  TRACK_RESEND_CLICK as OTP_TRACK_RESEND_CLICK,
} from 'reducers/send-otp';
import { UNK_ZIPCODE } from 'reducers/address';
import { EVENT_VIRTUAL_PAGE_VIEW, EVENT_CUSTOM } from 'reducers/promocode';

import { OPTION_CLICK } from 'reducers/navigation';
import { socialLoginResponseGA4 } from 'utils/data-layer/socialLoginGA4';
import { socialSignUpResponseGA4 } from 'utils/data-layer/socialSignUpGA4';
import { loginResponseGA4 } from 'utils/data-layer/loginGA4';
import { signupResponseGA4 } from 'utils/data-layer/signupGA4';
import addToDataLayer from 'utils/data-layer/addToDataLayer';
import { errorApplicationGA4 } from 'utils/data-layer/errorApplicationGA4';
import { pageviewLayerGA4 } from 'utils/data-layer/pageviewLayerGA4';
import { unknowZipCodeGA4 } from 'utils/data-layer/unknowZipCodeGA4';
import { acceptPrivacyPolicyGA4 } from 'utils/data-layer/acceptPrivacyPolicyGA4';
import { readPolicyGA4 } from 'utils/data-layer/readPolicyGA4';
import { openMpayAccount } from 'utils/data-layer/openMpayAccountGA4';
import { optionalNotificationsGA4 } from 'utils/data-layer/optionalNotificationsGA4';
import { continueCheckoutGA4 } from 'utils/data-layer/continueCheckoutGA4';
import { learnMoreMpayGA4 } from 'utils/data-layer/learnMoreMpayGA4';
import { termsOfUseGA4 } from 'utils/data-layer/termsOfUseGA4';
import {
  promocodeEventCustom,
  promocodeModalVirtualPageView,
} from 'utils/data-layer/promocode';

const eventActionMap = {
  [ADD_TO_CART]: addToCart,
  [ADD_TO_CART_GA4]: addToCartGA4,
  [PRODUCT_LIST_VIEW]: productListView,
  [VIEW_ITEM_LIST]: viewItemList,
  [BASKET_READY]: basketPageview,
  [BASKET_CHECKOUT_PROGRESS]: basketCheckoutProgress,
  [BASKET_VIEW_CART]: basketViewCart,
  [BASKET_TAX]: basketTax,
  [CUSTOMER_AREA_TRACK_START_CHANGE]: startChangeCustomer,
  [CUSTOMER_AREA_TRACK_SUCCESS_CHANGE]: successChangeCustomer,
  [CUSTOMER_AREA_TRACK_DATA_CHANGE]: dataChangeCustomer,
  [CUSTOMER_AREA_TRACK_DATA_CHANGE_ERROR]: ({ mode, description }) =>
    dataChangeResponse({ mode, status: 'erro', description }),
  [CUSTOMER_AREA_TRACK_CHANGE_DATA_SCREEN_OTP]: changeDataScreenOtp,
  [CUSTOMER_AREA_TRACK_SCREENVIEW_APP]: customVirtualPageview,
  [DID_UPDATE_ITEM_QTY]: changeItemQty,
  [DID_UPDATE_ITEM_QTY_GA4]: changeItemQtyGA4,
  [ERROR_APPLICATION]: errorApplicationGA4,
  [TRIGGER_CLICK_EVENT_GA4]: eventActionBasketGA4,
  [LOGIN_CHECKOUT_PROGRESS]: loginCheckoutProgress,
  [LOGIN_VIEW]: loginPageview,
  [LOGIN_RECEIVED]: () =>
    loginResponse({ status: 'sucesso', description: 'login-sucesso' }),
  [LOGIN_RECEIVED_GA4]: () => loginResponseGA4(),
  [LOGIN_TRACK_ERROR]: ({ errorMessage }) =>
    loginResponse({ status: 'erro', description: errorMessage }),
  [LOGIN_TRACK_SUBMIT]: () =>
    loginResponse({ status: 'click', description: 'login-continuar' }),
  [SIGNUP_RECEIVED]: () =>
    signupResponse({ status: 'sucesso', description: 'cadastro-sucesso' }),
  [VIEW_SINGUP_GA4]: pageviewLayerGA4,
  [UNK_ZIPCODE]: unknowZipCodeGA4,
  [CONTINUE_CHECKOU_GA4]: continueCheckoutGA4,
  [SIGNUP_RECEIVED_GA4]: signupResponseGA4,
  [SIGNUP_TRACK_ERROR]: ({ errorMessage }) =>
    signupResponse({ status: 'erro', description: errorMessage }),
  [SIGNUP_TRACK_SUBMIT]: () =>
    signupResponse({ status: 'click', description: 'cadastro-continuar' }),
  [REQUEST_PROFILE]: ({ source }) =>
    socialLoginResponse({ status: 'click', description: source }),
  [SOCIAL_LOGIN_RECEIVED]: ({ source }) =>
    socialLoginResponse({
      status: 'sucesso',
      description: `${source}-sucesso`,
    }),
  [SOCIAL_LOGIN_RECEIVED_GA4]: ({ source }) =>
    socialLoginResponseGA4({
      method: source,
    }),
  [RECEIVED_SIGN_UP_GA4]: ({ source }) =>
    socialSignUpResponseGA4({
      method: source,
    }),
  [SOCIAL_LOGIN_TRACK_ERROR]: ({ errorMessage, source }) =>
    socialLoginResponse({
      status: 'erro',
      description: `${source}-${errorMessage}`,
    }),
  [PAYMENT_VIEW]: paymentPageview,
  [PAYMENT_CHECKOUT_PROGRESS]: paymentCheckoutProgress,
  [PAYMENT_SHIPPING_CALC_IMPRESSION]: paymentShippingCalcImpression,
  [DID_REMOVE_ITEM]: removeToCart,
  [DID_REMOVE_ITEM_GA4]: removeFromCartGA4,
  [LEARN_MORE_MPAY_GA4]: learnMoreMpayGA4,
  [TERMS_USE_GA4]: termsOfUseGA4,
  [ORDER_VIEW]: orderPageview,
  [COPY_PIX_CLICK]: copyPixClick,
  [PRODUCT_CLICK]: productClick,
  [SELECT_ITEM]: selectItem,
  [RECEIVED_CLICK]: basketShippingCalc,
  [RECEIVED_IMPRESSION]: basketShippingCalcImpression,
  [RECEIVED_SHIPPING_CALC_ERROR_IN_BASKET]: basketShippingCalcError,
  [DID_REMOVE_SERVICES]: removeService,
  [OPTIONS_NOTIFICATIONS_GA4]: ({ message }) => optionalNotificationsGA4(message),
  [DID_CREATE_MPAY]: signupCreateMpay,
  [DID_CREATE_MPAY_GA4]: openMpayAccount,
  [DID_TERMS_KNOW_MORE_VIEW]: ({ source }) =>
    signupKnowMoreTerms({ description: source }),
  [ACCEPT_PRIVACY_POLICY_GA4]: ({ checked }) => acceptPrivacyPolicyGA4(checked),
  [READ_POLICY_GA4]: () => readPolicyGA4(),
  [OTP_TRACK_CLICK]: ({ description, page }) =>
    selectedModeSecurityValidationOtpClick({ description, page }),
  [OTP_TRACK_SUCCESS]: ({ description, page, mode }) =>
    otpResponse({ status: 'sucesso', description, page, mode }),
  [OTP_TRACK_ERROR]: ({ errorMessage, page, mode }) =>
    otpResponse({ status: 'erro', description: errorMessage, page, mode }),
  [OTP_TRACK_PAGE_VIEW]: ({ pageInfo }) => signupOtpVirtualPageView(pageInfo),
  [RECEIVED_PLACE_ORDER]: orderAddPaymentInfo,
  [RECEIVED_PLACE_ORDER_GA4]: orderAddPaymentInfoGA4,
  [REQUEST_PLACE_ORDER_GA4]: orderAddPaymentInfoGA4,
  [OPTION_CLICK]: ({ route, origin }) => navigationOptionClick({ route, origin }),
  [OTP_SELECT_OTHER_CONFIRMATION]: ({ mode }) =>
    selectOtherConfirmationOtpClick({ mode }),
  [OTP_TRACK_RESEND_CLICK]: ({ description, page }) =>
    resendOtpClick({ description, page }),
  [EVENT_VIRTUAL_PAGE_VIEW]: promocodeModalVirtualPageView,
  [EVENT_CUSTOM]: promocodeEventCustom,
};

export function dataLayerEvent(action, stateBefore, stateAfter, actionsMap) {
  const fn = actionsMap[action.type];

  if (!!fn) {
    try {
      const layer = fn(action, stateBefore, stateAfter);

      if (Array.isArray(layer)) {
        layer.forEach((l) => addToDataLayer(l));
      } else {
        addToDataLayer(layer);
      }

      return layer;
    } catch (error) {
      return null;
    }
  }
  return null;
}

export const dataLayerMiddleware = (store) => (next) => (action) => {
  const stateBefore = store.getState();
  const result = next(action);
  const stateAfter = store.getState();
  dataLayerEvent(action, stateBefore, stateAfter, eventActionMap);
  return result;
};

export default dataLayerMiddleware;
