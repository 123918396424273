import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonShowPassword from 'components/Form/ButtonShowPassword/ButtonShowPassword';
import Input from 'components/Input/Input';
import { REDIRECT_COMPANY_URL } from 'constants/channels';
import { BUTTON_LOGIN_ID, BUTTON_LOGIN_WITHOUT_CREDENTIALS_ID } from 'constants/ids';
import * as customLabes from 'constants/signin-labels';
import { loginViewContent } from 'utils/data-layer/helpers';
import { getChannelConfig } from 'utils/session';
import * as validators from 'utils/validators';
import addToDataLayer from 'utils/data-layer/addToDataLayer';
import './LoginBox.scss';

class LoginBox extends Component {
  static propTypes = {
    configs: PropTypes.object,
    onForgotPassword: PropTypes.func.isRequired,
    loginField: PropTypes.object.isRequired,
    passwordField: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    handleShowPasswordClick: PropTypes.func.isRequired,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isRequesting: PropTypes.bool.isRequired,
    captcha: PropTypes.node,
    useLoginPasswordless: PropTypes.func.isRequired,
    useLoginWithPassword: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    if (
      !!props.configs.type_authentication &&
      validators.isAuthenticationByCpf(props.configs.type_authentication)
    ) {
      this.state = { authentication_label: customLabes.labelCpf };
    } else if (
      !!props.configs.type_authentication &&
      validators.isAuthenticationByEmail(props.configs.type_authentication)
    ) {
      this.state = { authentication_label: customLabes.labelEmail };
    } else if (!getChannelConfig('enable_pj_customer_redirect_to_magalu_empresas')) {
      this.state = { authentication_label: customLabes.labelEmailCpfCnpj };
    } else {
      this.state = { authentication_label: customLabes.labelEmailCpfEnabledRedirect };
    }
  }

  handleChangeLogin = (event) => {
    this.props.loginField.onChange(event.target.value.toLowerCase());
  };

  handleChangePassword = (event) => {
    this.props.passwordField.onChange(event.target.value);
  };

  callAnalyticsOnBlur = (error) => {
    if (!!error) {
      loginViewContent({ content_id: `erro-login:${error}` });
    }
  }

  preSubmit = async (event) => {
    event.preventDefault();

    const buttonClicked = event.nativeEvent.submitter.name;

    if (buttonClicked === 'login-with-password') {
      await this.props.useLoginWithPassword();
    } else {
      addToDataLayer({
        event: 'select_content',
        content_type: 'identificacao:login',
        content_id: 'entrar-sem-senha',
        screenName: 'identificao-login'
      });

      await this.props.useLoginPasswordless();
    }
    
    return this.props.handleSubmit(event);
  }

  render() {
    const {
      loginField,
      passwordField,
      handleShowPasswordClick,
      showPassword,
      errorMessage: loginErrorMessage,
      isRequesting,
      captcha,
      onForgotPassword,
    } = this.props;
    const enablePasswordlessAuth = !!getChannelConfig('enable_passwordless_auth');
    const enablePJRedirect = getChannelConfig('enable_pj_customer_redirect_to_magalu_empresas') ?? false;
    const magaluEmpresasUrl = getChannelConfig(REDIRECT_COMPANY_URL);
    const formId = 'LoginBox-form';

    return (
      <div className="LoginBox">
        <div className="LoginBox-title">Já sou cliente</div>
        <form id={formId} className="LoginBox-form" onSubmit={this.preSubmit} method="post">
          <Input
            name={loginField.name}
            value={loginField.value}
            label={this.state.authentication_label}
            placeholder={
              enablePJRedirect
                ? 'Digite seu e-mail ou número do CPF'
                : 'Digite seu e-mail, CPF ou CNPJ'
            }
            error={loginField.error}
            invalid={loginField.invalid && !isRequesting}
            onChange={this.handleChangeLogin}
            touched={loginField.touched}
            onBlur={() => this.callAnalyticsOnBlur(loginField.error)}
          />
          <Input
            className="relative"
            classNameInput="pr-[40px] md:pr-[44px]"
            name={passwordField.name}
            value={passwordField.value}
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            placeholder="Digite sua senha"
            error={passwordField.error}
            invalid={passwordField.invalid && !isRequesting}
            onChange={this.handleChangePassword}
            touched={passwordField.touched}>
            <div className="absolute md:h-auto h-full right-[0] bottom-[0] -translate-y-[50%] top-[55%]">
              <ButtonShowPassword
                className=""
                field={showPassword}
                handleClick={() => handleShowPasswordClick('showPassword')}
              />
            </div>
          </Input>
          {loginErrorMessage && (
            <p className="LoginBox-form-error--unique">
              {loginErrorMessage.title && (
                <span className="font-2xsm-bold mb-2xsm">{loginErrorMessage.title}</span>
              )}
              <span>{loginErrorMessage?.description || loginErrorMessage}</span>
            </p>
          )}
          {captcha}
          <div
            className="LoginBox-form-button-options"
            role="group"
            aria-labelledby="opcoes-de-entrada">
            <button
              name="login-with-password"
              data-testid="login-with-password"
              type="submit"
              className="btn btn-success py-sm justify-center w-full"
              id={BUTTON_LOGIN_ID}>
              Continuar
            </button>
            {enablePasswordlessAuth && (
              <div>
                <span className="LoginBox-form-button-options-divisor">ou</span>
                <button
                  id={BUTTON_LOGIN_WITHOUT_CREDENTIALS_ID}
                  name="login-without-password"
                  data-testid="login-without-password"
                  type="submit"
                  className="btn py-sm btn-outline justify-center w-full">
                  Entrar sem senha
                </button>
              </div>
            )}
          </div>
        </form>
        <div className="LoginBox-help md:mt-sm">
          <a
            href="#"
            onClick={onForgotPassword}
            data-ga='{"category": "Login", "action": "Esqueci minha senha", "label": "Selecionou"}'>
            Esqueci minha senha
          </a>
        </div>
        {enablePJRedirect && (
          <div className="LoginBox-help">
            <strong className="LoginBox-strong">Possui CNPJ? </strong>Faça seu login
            pelo&nbsp;
            <a className="underline cursor-pointer" href={magaluEmpresasUrl}>
              Portal Magalu Empresas
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default LoginBox;
