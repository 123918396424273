import { couponsAvailableOnProducts, getCouponInBasket } from 'utils/couponHelpers';

export const openCouponModalActions = ({
  promocode,
  products,
  discounts,
  promocodeEventCustom,
  promocodeEventVirtualPageView,
}) => {
  const availableCoupons = couponsAvailableOnProducts(products);

  promocodeEventCustom({
    content_type: 'sacola',
    content_id: getCouponInBasket(promocode, discounts) ? 'ver-cupons' : 'inserir',
  });

  if (availableCoupons.length > 0) {
    const couponsListGA = availableCoupons.reduce(
      (acc, coupon, index) => `${acc}${index + 1}:${coupon.code.toLocaleLowerCase()};`,
      ''
    );
    promocodeEventCustom({
      event: 'view_content',
      content_id: `lista-de-cupons:${couponsListGA}`,
    });
  }

  promocodeEventVirtualPageView({
    couponsAvailableOnProducts: availableCoupons,
  });
};
