import { checkoutUrl } from 'constants/api-urls';

import { storageSaveCustomerData } from 'utils/session';
import getFingerprint from 'utils/fingerprint';
import request from 'utils/request';

import { redirectToMagaluEmpresas as routeRedirectToMagaluEmpresas } from 'constants/route-names';

import {
  customer as customerModel,
  truncatedEmail as truncatedEmailModel,
  socialSourceId as socialSourceIdModel,
  socialLoginUser as socialLoginUserModel,
  customerPFData as customerPFDataModel,
  customerPJData as customerPJDataModel,
  fullCustomerPF as fullCustomerPFModel,
  fullCustomerPJ as fullCustomerPJModel,
} from 'api/models/customer-models';

export const postLogin = (login, password, channel, token) => {
  return request('POST', `${checkoutUrl}/customer/login/`)
    .send({
      login,
      password,
      channel,
      captcha_token: token,
      tmx_national_sessionId: window.tmx_sessionID || null,
    })
    .then((res) => {
      return res.body;
    })
    .then((json) => {
      if (json.enabled_company_redirect) {
        const redirectUrl = `${routeRedirectToMagaluEmpresas}/${json.cnpj}?target=checkout_v2`;
        window.location.assign(redirectUrl);
        return { redirect: true };
      }

      storageSaveCustomerData(json);
      return customerModel(json);
    });
};

export const postSmartLock = (socialClientId) => {
  return request('POST', `${checkoutUrl}/socialnetwork/smartlock/`)
    .send({
      id_token: socialClientId,
      tmx_national_sessionId: window.tmx_sessionID || null,
    })
    .then((res) => {
      return res.body;
    });
};

export const postOneTap = (socialClientId) => {
  return request('POST', `${checkoutUrl}/socialnetwork/smartlock/`)
    .send({
      id_token: socialClientId,
      tmx_national_sessionId: window.tmx_sessionID || null,
    })
    .then((res) => {
      storageSaveCustomerData(res.body);
      return res.body;
    });
};

export const postTruncatedInfo = (data) => {
  return request('POST', `${checkoutUrl}/api/customer/get-truncated-info/`)
    .send(data)
    .then((res) => {
      return res.body;
    });
};

export const getSocialLogin = (source, accessToken, socialClientId, email) =>
  request('POST', `${checkoutUrl}/socialnetwork/client/social/`)
    .send({
      social_id: socialSourceIdModel(source),
      access_token: accessToken,
      social_client_id: socialClientId,
      email,
      tmx_national_sessionId: window.tmx_sessionID || null,
    })
    .then((res) => res.body)
    .then((json) => {
      storageSaveCustomerData(json);
      return socialLoginUserModel(json);
    });

export const postSocialSave = (source, socialClientId, email) => {
  return request('POST', `${checkoutUrl}/socialnetwork/save/`)
    .send({
      social_id: socialSourceIdModel(source),
      social_client_id: socialClientId,
      email,
    })
    .then((res) => res.body)
    .then((json) => socialLoginUserModel(json.data));
};

export const postIsUserRegistered = (captchaToken, accessToken, email) =>
  request('POST', `${checkoutUrl}/customer/email/`).send({
    captcha_token: captchaToken,
    access_token: accessToken,
    email,
  });

export const postSaveCustomerPF = async (details, captchaToken) => {
  const data = customerPFDataModel(details);
  data.captcha_token = captchaToken;
  const fingerprintHeaders = await getFingerprint();
  const httpMethod = data.uuid ? 'PUT' : 'POST';

  return request(`${httpMethod}`, `${checkoutUrl}/customer/save/`)
    .send(data)
    .set(fingerprintHeaders)
    .then((res) => res.body)
    .then((json) => {
      storageSaveCustomerData(json);
      return customerModel(json);
    });
};

export const postSaveCustomerPJ = async (details, captchaToken) => {
  const data = customerPJDataModel(details);
  data.captcha_token = captchaToken;
  const fingerprintHeaders = await getFingerprint();
  const httpMethod = data.uuid ? 'PUT' : 'POST';

  return request(`${httpMethod}`, `${checkoutUrl}/customer/save/`)
    .send(data)
    .set(fingerprintHeaders)
    .then((res) => res.body)
    .then((json) => {
      storageSaveCustomerData(json);
      return customerModel(json);
    });
};

export const patchEditEmailOrPhone = async (data) => {
  const path = data?.email ? 'customer/email' : 'customer/phone';

  return request('PATCH', `${checkoutUrl}/${path}`)
    .send(data)
    .then((res) => res.body)
    .then((json) => {
      storageSaveCustomerData(json);
      return json;
    });
};

export const getCpfOrCnpjAvailability = (cpfOrCnpj) => {
  return request('GET', `${checkoutUrl}/customer/validatecpfcnpj/${cpfOrCnpj}/`);
};

export const getDocumentAvailability = (cpfOrCnpj, captchaToken) => {
  return request('POST', `${checkoutUrl}/customer/document/`).send({
    document: cpfOrCnpj,
    captcha_token: captchaToken,
  });
};

export const getCustomer = () => {
  return request('GET', `${checkoutUrl}/customer/`)
    .then((res) => res.body)
    .then(
      (json) =>
        (json.data.isPerson && fullCustomerPFModel(json.data)) ||
        fullCustomerPJModel(json.data)
    );
};

export const putEmailNewsletter = async (status = true) => {
  const fingerprintHeaders = await getFingerprint();

  return request('PUT', `${checkoutUrl}/customer/update-email-newsletter/`)
    .send({ status })
    .set(fingerprintHeaders)
    .then(
      (res) => res.body.data.notifications?.emailPromotional,
      (err) => err
    );
};

export const postGenerateOtpCode = async (otpData) => {
  return request('POST', `${checkoutUrl}/customer/otp`)
    .send(otpData)
    .then((res) => res.body);
};

export const postValidateOtp = async (otpData) => {
  return request('POST', `${checkoutUrl}/customer/otp/validate`)
    .send(otpData)
    .then((res) => res.body);
};

export const getMiniappToken = (sessionId) => {
  return request('GET', `${checkoutUrl}/customer/app/`)
    .set('x-session', sessionId)
    .then((res) => res.body);
};
