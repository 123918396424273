import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductPromoCodeTag from './ProductPromoCodeTag';
import InfoPromoCodeTag from './InfoPromoCodeTag';

class PromoCodeTag extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['small', 'medium', 'big']),
    promo: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string.isRequired,
    }).isRequired,
    button: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    success: PropTypes.string,
    error: PropTypes.string,
    listItem: PropTypes.bool,
    onRemove: PropTypes.func,
  };

  render() {
    const { type, promo, button, success, error, listItem, onRemove } = this.props;

    return type === 'big' ? (
      <InfoPromoCodeTag
        promo={promo}
        button={button}
        success={success}
        error={error}
        listItem={listItem}
        onRemove={onRemove}
      />
    ) : (
      <ProductPromoCodeTag type={type} promo={promo} />
    );
  }
}

PromoCodeTag.defaultProps = {
  type: 'big',
  listItem: false,
};

export default PromoCodeTag;
