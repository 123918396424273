import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { defaultDelivery } from 'constants/delivery-choices';

import { productUrl } from 'utils/urlChannelParser';
import { isOutOfStockProduct, isItemNotAllowedForCompaniesProduct } from 'utils/product';

import { deleteServiceData } from 'api/models/basket-models';

import * as basketActions from 'reducers/basket';

import BasketTable from 'components/Basket/BasketTable/BasketTable';

import BasketItem from 'components/Basket/BasketItem/BasketItem';

import BasketTableByPackage from 'components/Basket/BasketTableByPackage/BasketTableByPackage';

const mapStateToProps = ({ basket, shipment, store, channel, order }) => ({
  ...basket,
  ...shipment,
  ...store,
  ...order,
  policies: order.orderViolatedPolicies,
  itemsNotAllowedForCompanies: order.itemsNotAllowedForCompanies,
  configs: channel.configs,
  maxProductQuantity: channel.configs.maximum_quantity_per_sku,
  restrictedSkus: channel.configs.restricted_skus,
});

const mapDispatchToActions = {
  ...basketActions,
};

@connect(mapStateToProps, mapDispatchToActions)
export default class BasketTableContainer extends Component {
  static propTypes = {
    basket: PropTypes.shape({
      products: PropTypes.array,
    }).isRequired,
    shipment: PropTypes.shape({
      zipcode: PropTypes.string,
      outOfStockProducts: PropTypes.array,
    }).isRequired,
    putItemQuantity: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    deleteWarranty: PropTypes.func.isRequired,
    deleteServices: PropTypes.func.isRequired,
    fetchBasket: PropTypes.func.isRequired,
    configs: PropTypes.object.isRequired,
    maxProductQuantity: PropTypes.number.isRequired,
    restrictedSkus: PropTypes.array.isRequired,
    itemsNotAllowedForCompanies: PropTypes.array.isRequired,
    policies: PropTypes.array,
  };

  render() {
    const {
      basket: { products, customerPerson, unavailableDeliveries },
      shipment: { zipcode, outOfStockProducts },
      putItemQuantity,
      deleteItem,
      deleteWarranty,
      deleteServices,
      fetchBasket,
      configs,
      shipment,
      maxProductQuantity,
      restrictedSkus,
      itemsNotAllowedForCompanies,
      policies,
    } = this.props;
    const unavailableItems = JSON.parse(localStorage.getItem('unavailableItems')) || [];

    const removeItemUnavailableLocalStorage = (sku) => {
      const filteredUnavailableLocalStorage = unavailableItems.filter(item => item.sku !== sku);
      localStorage.setItem('unavailableItems', JSON.stringify(filteredUnavailableLocalStorage));
    };

    const isSoldOut = (product) => {
      const hasItems = unavailableItems.find(item => item.sku === product.sku);
      const outOfStock = hasItems && hasItems.status_message === 'Produto esgotado.';
      const unavailableFromStorage = outOfStock ? unavailableItems : [];

      return isOutOfStockProduct(product.id, outOfStockProducts?.length ? outOfStockProducts : unavailableFromStorage) || product.isSoldOut;
    };

    const isItemNotAllowedForCompanies = (product) => {
      const hasItems = unavailableItems.find(item => item.sku === product.sku);
      const notAllowedForCompanies = hasItems && hasItems.status_message === 'Items not allowed for companies.';
      const unavailableFromStorage = notAllowedForCompanies ? unavailableItems : [];

      return isItemNotAllowedForCompaniesProduct(product.id, itemsNotAllowedForCompanies?.length ? itemsNotAllowedForCompanies : unavailableFromStorage);
    };

    const productsSortedBySoldOut = [...products].sort((product) =>
      isSoldOut(product) ? -1 : 1
    );

    const groupedPackages = {};

    const getConventionalDeliveryType = (deliveryTypes) => {
      return deliveryTypes.find((type) => type?.type === 'conventional');
    };

    const isProductsNotAvailable = (product) => {
      return (
        product?.isSoldOut ||
        product?.notAvailableForDelivery ||
        isItemNotAllowedForCompanies(product)
      );
    };

    for (const shippingPackage of shipment?.packages) {
      if (!groupedPackages[shippingPackage?.deliveryId]) {
        groupedPackages[shippingPackage?.deliveryId] = [];
      }

      const conventionalDeliveryType = getConventionalDeliveryType(
        shippingPackage?.deliveryTypes
      );

      for (const shippingProduct of products) {
        const matchedProduct = shippingPackage?.products.find(
          (product) =>
            (shippingProduct?.sku === product?.sku ||
              shippingProduct?.sku === product?.bundle_sku) &&
            !isProductsNotAvailable(product)
        );
        if (matchedProduct) {
          groupedPackages[shippingPackage?.deliveryId].push({
            ...shippingProduct,
            deliveryType: conventionalDeliveryType,
          });
        }
      }

      if (groupedPackages[shippingPackage?.deliveryId].length === 0) {
        delete groupedPackages[shippingPackage?.deliveryId];
      }
    }

    const isZipCodeFilled = Boolean(zipcode);

    const renderBasketItems = (items) => {
      return items.map((product, index, array) => {
        const { storeId, id, sku } = product;
        const price = product.prices.to;
        const isLastItemForKey = index === array.length - 1;

        const delivery = shipment.packages.filter((pkg) =>
          pkg.products.find((productPackage) =>
            productPackage.bundle_sku
              ? productPackage.bundle_sku === sku || sku
              : productPackage.sku === sku || id
          )
        );

        const hasDelivery = delivery.length > 0;
        const firstDelivery = delivery[0];
        const hasDeliveryShippingTime =
          hasDelivery &&
          firstDelivery.deliveryTypes.every(
            ({ shipping_time: shippingTime }) => !!shippingTime
          );

        const shippingTimes = hasDeliveryShippingTime
          ? firstDelivery.deliveryTypes
            .filter((deliveryType) => deliveryType.type === defaultDelivery)
            .map((deliveryType) => deliveryType.shipping_time)
          : [];

        const maxQuantity = product.maxQuantity;
        const minQuantity = product.minQuantity;
        const currency = product.currency;
        const tax = product.tax;

        const unavailableDeliveryInfo = () => {
          if (unavailableItems.length) {
            return unavailableItems?.find((item) => item.sku === sku);
          }

          return unavailableDeliveries?.find(
            (unavailableDelivery) => unavailableDelivery.sku === sku
          );
        };

        return (
          <BasketItem
            violatedPolicies={policies}
            handleWarrantyRemove={function handleWarrantyRemove(e) {
              e.preventDefault();
              deleteWarranty(storeId, id);
            }}
            handleServiceRemove={function handleServiceRemove(e, service) {
              e.preventDefault();

              deleteServices(
                deleteServiceData([
                  {
                    id,
                    storeId,
                    services: [service.id],
                    service,
                  },
                ]),
                product
              );
            }}
            handleItemQuantityUpdate={function handleItemQuantityUpdate(e) {
              putItemQuantity(storeId, id, Number(e.target.value), product).then(
                fetchBasket
              );
            }}
            handleItemRemove={function handleItemRemove(e) {
              e.preventDefault();
              deleteItem([{ storeId, id, price }]);
              removeItemUnavailableLocalStorage(id);
            }}
            productUrl={productUrl(product, configs)}
            key={index + storeId}
            product={product}
            zipcode={zipcode}
            shippingTimes={shippingTimes}
            customerPerson={customerPerson}
            channelConfigs={configs}
            maxProductQuantity={maxQuantity ? maxQuantity : maxProductQuantity}
            restrictedSkus={restrictedSkus}
            isSoldOut={isSoldOut(product)}
            isItemNotAllowedForCompanies={isItemNotAllowedForCompanies(product)}
            maxQuantity={maxQuantity}
            minQuantity={minQuantity}
            isLastItemForKey={isLastItemForKey}
            currency={currency}
            tax={tax}
            unavailableDeliveryInfo={unavailableDeliveryInfo()}
          />
        );
      });
    };

    const renderProducts = () => {
      if (!isZipCodeFilled) {
        return <BasketTable>{renderBasketItems(productsSortedBySoldOut)}</BasketTable>;
      }

      if (isZipCodeFilled) {
        return (
          <div>
            {productsSortedBySoldOut.find(
              (product) => product.isSoldOut || product.notAvailableForDelivery
            ) && (
              <BasketTable isProductsNotAvailable>
                {renderBasketItems(
                  productsSortedBySoldOut.filter(
                    (product) => product.isSoldOut || product.notAvailableForDelivery
                  )
                )}
              </BasketTable>
            )}

            {itemsNotAllowedForCompanies.find((product) => product.sku) && (
              <BasketTable isProductsNotAvailable>
                {renderBasketItems(
                  products.filter((product) => isItemNotAllowedForCompanies(product))
                )}
              </BasketTable>
            )}

            <BasketTableByPackage groupedProducts={groupedPackages}>
              {Object.entries(groupedPackages).map(([key, productsInGroup]) =>
                renderBasketItems(productsInGroup)
              )}
            </BasketTableByPackage>
          </div>
        );
      }
    };

    return renderProducts();
  }
}
