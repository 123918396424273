import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { showcase as Showcase } from 'constants/recommendation';

import * as basketActions from 'reducers/basket';
import * as shipmentActions from 'reducers/shipment';
import * as loadingActions from 'reducers/loading';
import * as metricsActions from 'reducers/metrics';
import * as recommendationActions from 'reducers/recommendation';

import MagazineModal from 'components/MagazineModal/MagazineModal';
import BasketProducts from 'components/Basket/BasketProducts';
import BasketEmpty from 'components/Basket/BasketEmpty';
import { filterSoldOutProducts } from '../../../utils/product';
import stewie from 'utils/stewie';
import { clearPaymentBasketIdInSessionStorage } from 'utils/session';

const basketLoadingID = 'basketLoading';
const shipmentLoadingID = 'shipmentLoading';
const isRecommendationsEnabled = window.MAGALU_CHANNEL?.channel_configs?.enable_recommendations;

const {
  sacola: {
    page_id: pageId,
    placement_sacolatop: sacolaTop,
    placement_sacolabottom: sacolaBottom,
    placement_sacolasemestoquetop: sacolaSemEstoqueTop,
    placement_sacolasemestoquebottom: sacolaSemEstoqueBottom,
  }
} = Showcase;

const mapStateToProps = ({
  basket,
  shipment,
  channel,
  recommendation,
}) => ({
  ...basket,
  ...recommendation,
  basketRequesting: basket.isRequesting,
  shipmentRequesting: shipment.isRequesting,
  originUrl: channel.configs.origin_url,
  fullShowcaseName: channel.configs.full_showcase_name,
});

const mapDispatchToActions = {
  ...basketActions,
  ...loadingActions,
  ...recommendationActions,
  ...metricsActions,
  refreshZipcodeFromCookies: shipmentActions.refreshZipcodeFromCookies
};

@connect(mapStateToProps, mapDispatchToActions)
export default class BasketPage extends Component {
  static propTypes = {
    basket: PropTypes.shape({
      products: PropTypes.array.isRequired,
    }).isRequired,
    errorMessage: PropTypes.string,
    firstRequest: PropTypes.bool.isRequired,
    fetchBasket: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    trendsPurchases: PropTypes.object,
    trendsViews: PropTypes.object,
    toggleLoading: PropTypes.func.isRequired,
    basketRequesting: PropTypes.bool.isRequired,
    shipmentRequesting: PropTypes.bool.isRequired,
    resetBasket: PropTypes.func.isRequired,
    refreshZipcodeFromCookies: PropTypes.func.isRequired,
    originUrl: PropTypes.string.isRequired,
    fullShowcaseName: PropTypes.string,
    fetchShowcasesRecommendation: PropTypes.func.isRequired,
    metricsError: PropTypes.func.isRequired,
  };

  componentDidMount() {
    stewie();
  }

  componentWillMount() {
    const {
      fetchBasket,
      refreshZipcodeFromCookies,
      fetchShowcasesRecommendation,
      metricsError
    } = this.props;

    clearPaymentBasketIdInSessionStorage();
    refreshZipcodeFromCookies();
    fetchBasket()
      .then(({ products }) => {
        if (isEmpty(products)) {
          if (isRecommendationsEnabled) {
            return fetchShowcasesRecommendation().catch((err) => {
              const json = err.response ? err.response.body : {};
              metricsError('recomendacao', json?.error_message);
            });
          }
        }

        const soldOutProducts = filterSoldOutProducts(products);

        if (soldOutProducts.length === 1) {
          if (isRecommendationsEnabled) {
            const soldOutItems = soldOutProducts
              .map(product => ({ sku: product.sku, seller: product.storeId }));

            fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaSemEstoqueTop },
              items: soldOutItems,
            }).catch((err) => {
              if (err !== 'Already requesting showcase recommendation.') {
                const json = err.response ? err.response.body : {};
                metricsError('recomendacao', json?.error_message);
              }
            });

            return fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaSemEstoqueBottom },
              items: soldOutItems,
            });
          }
        } else {
          const items = products.map(product => ({ sku: product.sku, seller: product.storeId }));

          if (isRecommendationsEnabled) {
            fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaBottom },
              items,
            });

            return fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaTop },
              items,
            }).catch((err) => {
              if (err !== 'Already requesting showcase recommendation.') {
                const json = err.response ? err.response.body : {};
                metricsError('recomendacao', json?.error_message);
              }
            });
          }
        }
      })
      .catch((err) => {
        if (err?.status >= 400) {
          if (isRecommendationsEnabled) {
            fetchShowcasesRecommendation()
              .catch((er) => {
                const json = er.response ? er.response.body : {};
                metricsError('recomendacao', json?.error_message);
              });
          }
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    const {
      toggleLoading,
      basketRequesting,
      shipmentRequesting,
      basket: { products },
      fetchShowcasesRecommendation,
      metricsError,
    } = nextProps;

    const currentProductsIds = this.props.basket.products.map(({ id }) => id);
    const nextProductsIds = products.map(({ id }) => id);


    // We MUST check only IDS because we dont need to fetch wvav
    // if customer just change quantity
    if (!isEqual(nextProductsIds, currentProductsIds)) {
      if (isEmpty(nextProductsIds)) {
        if (isRecommendationsEnabled) {
          fetchShowcasesRecommendation()
            .catch((err) => {
              const json = err.response ? err.response.body : {};
              metricsError('recomendacao', json?.error_message);
            });
        }
      } else {
        const soldOutProducts = filterSoldOutProducts(products);

        if (soldOutProducts.length === 1) {
          if (isRecommendationsEnabled) {
            const soldOutItems = soldOutProducts
              .map(product => ({ sku: product.sku, seller: product.storeId }));

            fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaSemEstoqueTop },
              items: soldOutItems,
            }).catch((err) => {
              if (err !== 'Already requesting showcase recommendation.') {
                const json = err.response ? err.response.body : {};
                metricsError('recomendacao', json?.error_message);
              }
            });

            fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaSemEstoqueBottom },
              items: soldOutItems,
            });
          }
        } else {
          if (isRecommendationsEnabled) {
            const items = products.map(product => ({ sku: product.sku, seller: product.storeId }));

            fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaBottom },
              items,
            }).catch((err) => {
              if (err !== 'Already requesting showcase recommendation.') {
                const json = err.response ? err.response.body : {};
                metricsError('recomendacao', json?.error_message);
              }
            });

            fetchShowcasesRecommendation({
              params: { pageId, placementId: sacolaTop },
              items,
            }).catch((err) => {
              if (err !== 'Already requesting showcase recommendation.') {
                const json = err.response ? err.response.body : {};
                metricsError('recomendacao', json?.error_message);
              }
            });
          }
        }
      }
    }

    toggleLoading(basketLoadingID, basketRequesting);
    toggleLoading(shipmentLoadingID, shipmentRequesting);
  }

  componentWillUnmount() {
    const { resetBasket } = this.props;
    return resetBasket();
  }

  render() {
    const {
      basket: { products },
      errorMessage,
      firstRequest,
      clearError,
      trendsPurchases,
      trendsViews,
      trendsCarts,
      originUrl,
      fullShowcaseName,
      recommendations,
    } = this.props;

    const purchaseMoreLink = (
      !!fullShowcaseName
        ? `${originUrl}/${fullShowcaseName}`
        : originUrl
    );

    const modal = (
      <MagazineModal
        isOpen={!!errorMessage}
        onRequestClose={clearError}
      >
        {errorMessage}
      </MagazineModal>
    );

    const contentBasket = (
      products.length > 0
        ? (
          <BasketProducts
            modal={modal}
            recommendations={recommendations}
          />
        )
        : (
          <BasketEmpty
            modal={modal}
            products={products}
            trendsPurchases={trendsPurchases}
            trendsViews={trendsViews}
            trendsCarts={trendsCarts}
            purchaseMoreLink={purchaseMoreLink}
            recommendations={recommendations}
          />
        )
    );

    return (
      <div className="BasketPage">
        {firstRequest
          ? <span />
          : <div>
            {contentBasket}
          </div>
        }
      </div>
    );
  }
}
