import { productName } from './productName';

export const getCouponInProduct = (product) => {
  const couponInDiscounts = product?.discounts?.find((discount) =>
    discount.description.includes('Promocode:')
  );

  if (couponInDiscounts){
    return productName(couponInDiscounts?.description?.split(':').pop());
  }

  const couponInTags = product?.tags?.find((tag) => tag.type === 'coupon');

  if (couponInTags) return productName(couponInTags?.code);

  return null;
};
