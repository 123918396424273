import { toBRL } from './currency';
import moment from 'moment';

moment.locale('pt-br');

export const getCouponInBasket = (promocode, discounts) => {
  if (promocode && discounts?.promocode) return promocode;

  return '';
};

export const dateExpired = (dateToCompare) => {
  const format = (date) => moment(date).format('DD/MM/YYYY');
  const [day, month, year] = format(dateToCompare)
    .split('/')
    .map((time) => parseInt(time, 10));
  const formatDateToCompare = moment([year, month - 1, day]);
  const dateNow = moment();
  const isExpired =
    format(dateNow) === format(formatDateToCompare)
      ? false
      : dateNow.isAfter(formatDateToCompare);

  return isExpired;
};

export const couponsAvailableOnProducts = (products) => {
  if (products?.length === 0) return [];

  const availableCouponTags = products.reduce((acc, { tags }) => {
    if (tags?.length > 0) {
      const couponTag = tags.find(
        ({ type, discount_value, end_date }) =>
          type === 'coupon' && discount_value && !dateExpired(end_date)
      );
      if (couponTag) return [...acc, couponTag];
    }

    return acc;
  }, []);

  return availableCouponTags;
};

export const formatDiscount = (discountValue, discountType, parseToInt = false) => {
  const discountFormats = {
    percentage: `${parseInt(discountValue, 10)}% OFF`,
    absolute: `${parseToInt ? `R$ ${parseInt(discountValue, 10)}` : toBRL(discountValue)} OFF`,
  };

  return discountFormats[discountType];
};

export const couponTitle = (tag) =>
  `${formatDiscount(tag.discount_value, tag.discount_type)} com cupom: ${tag.code}`;
