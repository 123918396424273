import React from 'react';
import PropTypes from 'prop-types';

import * as externalUrls from 'constants/external-urls';
import * as maskers from 'utils/maskers';
import { buildPictureUrl, resizeProductPicture } from 'utils/pictures';

import BasketItemAdditionalProduct from '../BasketItemAdditionalProduct';
import BasketCustomerCompanyServicesWarning from '../../Warnings/BasketCustomerCompanyServicesWarning';
import BasketSoldBy from 'components/shared/BasketSoldBy/BasketSoldBy';
import TagInternational from 'components/TagInternational/TagInternational';
import PromoCodeTag from 'components/HighlightPromoCode/PromoCodeTag/PromoCodeTag';
import isCouponJourneyEnable from 'utils/isCouponJourneyEnable';
import { couponsAvailableOnProducts, formatDiscount } from 'utils/couponHelpers';
import './BasketItemProduct.scss';

const BasketItemProduct = ({
  id,
  title,
  store,
  rawPictureUrl,
  fullPictureUrl,
  reference,
  productUrl,
  gifts,
  variations,
  customerPerson,
  isSoldOut,
  services,
  unavailable,
  isInternational,
  tags,
}) => {
  const titleReference = reference ? ` - ${reference}` : '';
  const titleText = `${title}${titleReference}`;

  const pictureUrl =
    fullPictureUrl !== undefined
      ? resizeProductPicture(fullPictureUrl, 102, 76)
      : buildPictureUrl(
          externalUrls.pictureBaseUrl,
          rawPictureUrl,
          title,
          reference,
          102,
          76
        );

  const hasCustomerCompanyServicesWarning = () => {
    return !isSoldOut && services && services.length > 0 && customerPerson === false;
  };

  const giftsList = (list) =>
    list.map((item) => (
      <BasketItemAdditionalProduct
        key={item.id}
        title={`+ Brinde: ${item.title}`}
        productUrl={productUrl}
      />
    ));

  const [availableCoupon] = couponsAvailableOnProducts([{ tags }]);

  const classInfoTitle = 'BasketItemProduct-info-title';

  const classNameInfoTitle = !unavailable
    ? classInfoTitle
    : `${classInfoTitle}--unavailable`;

  const classInfoSku = 'BasketItemProduct-info-sku';

  const classNameInfoSku = !unavailable ? classInfoSku : `${classInfoSku}--unavailable`;

  return (
    <div className="BasketItemProduct">
      <a className="BasketItemProduct-image" href={productUrl}>
        <img src={pictureUrl} />
      </a>
      <div className="BasketItemProduct-info">
        <a className={classNameInfoTitle} href={productUrl}>
          <p>{titleText}</p>
          {!!(variations && variations.length) && (
            <p className={classNameInfoSku}>{maskers.variationsValues(variations)}</p>
          )}
          <p className={classNameInfoSku}>Código do produto: {id}</p>
        </a>
        {isCouponJourneyEnable() && availableCoupon && (
          <div className="w-full">
            <PromoCodeTag
              type="small"
              promo={{
                message: `Cupom ${formatDiscount(
                  availableCoupon.discount_value,
                  availableCoupon.discount_type,
                  true
                )}`,
              }}
            />
          </div>
        )}
        {isInternational && (
          <div className="BasketItemProduct-tag-international">
            <TagInternational />
          </div>
        )}

        {gifts && giftsList(gifts)}

        <div className="BasketItemProduct-info-store">
          {store && (
            <span className="BasketItemProduct-informative">
              <BasketSoldBy store={store} unavailable={unavailable} />
            </span>
          )}
        </div>

        {hasCustomerCompanyServicesWarning() && <BasketCustomerCompanyServicesWarning />}
      </div>
    </div>
  );
};

BasketItemProduct.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  rawPictureUrl: PropTypes.string.isRequired,
  fullPictureUrl: PropTypes.string,
  reference: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired,
  gifts: PropTypes.array,
  variations: PropTypes.array,
  customerPerson: PropTypes.bool,
  isSoldOut: PropTypes.bool.isRequired,
  unavailable: PropTypes.bool.isRequired,
  isInternational: PropTypes.bool,
};

export default BasketItemProduct;
