import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class PromoCodeInput extends Component {
  static propTypes = {
    onApplyCoupon: PropTypes.func.isRequired,
    onRemoveCoupon: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    getCouponInBasket: PropTypes.func.isRequired,
    couponInput: PropTypes.string.isRequired,
    onChangeCoupon: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { onApplyCoupon, couponInput } = this.props;

    if (!couponInput) return;

    onApplyCoupon(couponInput);
  };

  render() {
    const {
      getErrorMessage,
      getCouponInBasket,
      couponInput,
      onChangeCoupon,
      onRemoveCoupon,
    } = this.props;
    const error = getErrorMessage(couponInput || '');
    const couponApplied = !!getCouponInBasket();

    return (
      <div className="input-group mt-2xsm mb-md lg:mx-auto lg:w-full">
        <input
          className={classname('input-text w-full pr-[0]', {
            'error': error,
          })}
          type="text"
          placeholder="Código do cupom"
          id="promo-code-input"
          value={couponInput}
          onChange={onChangeCoupon}
          onKeyDown={(e) => e.key === 'Enter' && this.handleClick()}
        />

        <div className="absolute right-[0] flex items-center h-full md:right-xsm">
          {couponApplied ? (
            <span
              className="input-reset icon-highlight-off cursor-pointer flex mr-sm text-on-surface-4"
              role="button"
              tabIndex={0}
              id="promo-code-remove"
              onClick={onRemoveCoupon}
            />
          ) : (
            <button
              className="btn btn-invisible font-body-small-highlight text-brand-default"
              type="button"
              id="promo-code-apply"
              onClick={this.handleClick}>
              Aplicar
            </button>
          )}
        </div>

        {couponApplied && !error && (
          <span className="input-info icon-check !text-on-brand-default-inverted absolute">
            Cupom aplicado
          </span>
        )}

        {error && (
          <span className="input-info icon-info !text-on-danger-default-inverted absolute">
            {error}
          </span>
        )}
      </div>
    );
  }
}

export default PromoCodeInput;
